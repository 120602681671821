<template>
  <div>
    <div class="tabs">
      <div
        class="tabs-item"
        :class="{ active: active == index }"
        v-for="(item, index) in tabsList"
        :key="index"
        @click="tabChange(index)"
      >
        {{ item.name }}
      </div>
    </div>
    <van-pull-refresh
      v-model="isLoading"
      @refresh="onRefresh"
      style="min-height: 100vh"
    >
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-list
        v-else
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :offset="500"
        @load="onLoad"
      >
        <div
          class="list-item slideInRight"
          v-for="(item, index) in dataList"
          :key="index"
        >
          <i class="icon"></i>
          <div class="content">
            <div class="title" v-if="item.payStr">
              {{ item.payStr }}
            </div>
            <div class="title" v-if="item.bank_name">
              {{ item.bank_name }}
            </div>
            <div class="time">
              {{ item.status == 0 ? item.add_time : item.arrival_time }}
            </div>
          </div>
          <div class="content2">
            <div class="amount">+{{ item.money }}</div>
            <div class="tips" :class="item.status_str | classFilter">
              {{ item.status_str }}
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { cashOut } from "@/api/recordDetails";
export default {
  data() {
    return {
      active: 0,
      isLoading: false,
      loading: false,
      finished: false,
      showEmpty: false,
      tabsList: [
        {
          name: "全部",
          status: ""
        },
        {
          name: "审核",
          status: 0
        },
        {
          name: "已结算",
          status: 1
        },
        {
          name: "已失败",
          status: 2
        }
      ],
      dataList: [],
      page: 1
    };
  },
  methods: {
    tabChange(index) {
      this.active = index;
      this.page = 1;
      this.dataList = [];
      this.getList();
    },
    onRefresh() {
      this.page = 1;
      this.dataList = [];
      this.getList();
    },
    onLoad() {
      this.getList();
    },
    getList() {
      let params = {
        page: this.page,
        status: this.tabsList[this.active].status
      };
      this.isLoading = true;
      cashOut(params).then(res => {
        // console.log("11111111111");
        console.log(res.data.data);
        if (res.data.code == 200) {
          this.dataList.push(...res.data.data);
          ++this.page;
          this.loading = false;
          this.isLoading = false;
          if (this.dataList.length <= 0 && res.data.data.length <= 0) {
            this.showEmpty = true;
          } else {
            this.showEmpty = false;
          }
          if (this.dataList.length >= 0 && res.data.data.length <= 0) {
            this.finished = true;
          }
        }
      });
    }
  },
  mounted() {
    // this.getList();
  },
  filters: {
    classFilter(i) {
      switch (i) {
        case "审核中":
          return "lightRed";
        case "已结算":
          return "grey";
        case "已失败":
          return "red";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
body {
  background-color: red;
}
.tabs {
  height: 103px;
  background: #faf6f3;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 20px;
  &-item {
    width: 153px;
    height: 56px;
    background: #ffffff;
    border-radius: 28px;
    font-size: 25px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #999999;
    line-height: 56px;
    text-align: center;
    box-sizing: border-box;
  }
  .active {
    color: #ffffff;
    background: #fbc034;
  }
}
.list-item {
  margin-top: 21px;
  width: 703px;
  height: 149px;
  background: #ffffff;
  border-radius: 14px;
  margin: auto;
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 38px 21px;
  margin-bottom: 22px;
  .icon {
    width: 29px;
    height: 28px;
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title {
      font-size: 29px;
      font-weight: 500;
      color: #333333;
    }
    .time {
      font-size: 24px;
      font-weight: 400;
      color: #999999;
    }
  }
  .content2 {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .amount {
      margin-left: auto;
      font-size: 35px;
      font-weight: 500;
      color: #333333;
    }
    .tips {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
    }
    .lightRed {
      color: #ff894f;
    }
    .grey {
      color: #999999;
    }
    .red {
      color: #ff4f4f;
    }
  }
}
@keyframes slideInRight {
  0% {
    opacity: 0;
    margin-bottom: 500px;
  }
  100% {
    margin-bottom: 22px;
  }
}
.slideInRight {
  animation: 0.8s slideInRight;
}
</style>
